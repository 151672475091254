import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule, EntityDefinitionService, HttpUrlGenerator } from '@ngrx/data';
import { entityConfig } from './entity-metadata';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoginComponent } from './login/login.component';
import { EmailListComponent } from './email/email-list/email-list.component';
import { EmailEntityMetadata } from './store/email-entity-metadata';
import { CustomHttpInterceptorService } from './_service/_interceptor/custom-http-interceptor.service';
import { CustomHttpGenerator } from './shared/store/custom-http-generator';
import { HeaderComponent } from './layout/header/header.component';
import { EmailViewComponent } from './email/email-view/email-view.component';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EmailListComponent,
    HeaderComponent,
    EmailViewComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot(entityConfig),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() })
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CustomHttpInterceptorService,
    //   multi: true
    // },
    {
      provide: HttpUrlGenerator,
      useClass: CustomHttpGenerator
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(entityDefinitionService: EntityDefinitionService){
    entityDefinitionService.registerMetadataMap(EmailEntityMetadata)
  }
 }

