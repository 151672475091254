import { EntityMetadataMap } from "@ngrx/data";
import { IeMail } from "./i-email";

export const EmailEntityMetadata: EntityMetadataMap = {
    eMail:{
        selectId: (eMail: IeMail) => eMail.mailUID
    },
    delete:{
        entityDispatcherOptions:{optimisticDelete:true}
    },
    update:{
        entityDispatcherOptions:{optimisticUpdate:true}
    }

}
