import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router  } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Subject } from 'rxjs';
import {AppConstants} from 'src/app/_helper/app.constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  ApiBaseURL: string="";
  connectionId: string="";
  Username: string="";
  constructor(private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    this.ApiBaseURL = AppConstants.API_BASE_URL;
  }
  doLogin(_event:any){
    _event.preventDefault();
    sessionStorage["User"] = this.Username;
    this.router.navigate(['/', this.Username]);
  }
  SignalR(){}

}
