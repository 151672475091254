<app-header></app-header>
<section class="row">
    <div class="colleft-side-mail-box col-md-3">
        <app-email-list></app-email-list>
    </div>
    <div class="right-side-content col-md-8">
        <div class="subject-div">
            <h4>Sub: {{(currentMail$ | async)?.mailSubject }}</h4>
        </div>
        <div class="bottom-to-details d-flex justify-content-between flex-wrap">
            <div class="left-text">
                <p class="font-bold">From: {{(currentMail$ | async)?.mailFrom }}</p>
                <p class="margin-0">To: {{(currentMail$ | async)?.mailTo }}</p>
            </div>
            <div class="right-text">
                <p class="small-text">{{(currentMail$ | async)?.mailDateTime }}</p>
            </div>
           
        </div>

        <div class="conten-area">
            <div id="mailBody" [innerHTML]="(currentMail$ | async)?.mailBody"></div>
        </div>

    </div>
</section>
