import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { EntityCollectionService, EntityCollectionServiceFactory } from '@ngrx/data';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { IeMail } from 'src/app/store/i-email';
import { AppConstants } from 'src/app/_helper/app.constants';
@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private ApiBaseURL: string="";
  private _hubConnection: any;
  eMailService: EntityCollectionService<IeMail>;
  _IeMail: any = null;
  currentMail$:BehaviorSubject<IeMail> = new BehaviorSubject<IeMail>(this._IeMail);
  currentMailUID: number =0;
  mailCount: number =0;
  public newmailCount = new BehaviorSubject<number>(0);

  constructor(serviceFactory: EntityCollectionServiceFactory, route: ActivatedRoute){
    this.eMailService = serviceFactory.create<IeMail>('eMail');
    this.iniSignalR();
    this.iniMail();
  } 
  iniMail(): void {
    if (this.currentMailUID == 0){
      this.eMailService.entities$?.subscribe (ml=> {
        let aryMail: any[];
        aryMail =ml;
        aryMail = aryMail || [];
        this.currentMail$.next(aryMail.at(-1));
        this.currentMailUID = aryMail.at(-1)?.mailUID;
        this.mailCount = aryMail.length;
      });
    }
  }
  iniSignalR(){
    this.ApiBaseURL = AppConstants.API_BASE_URL;
    this._hubConnection = new signalR.HubConnectionBuilder().withUrl(this.ApiBaseURL +'signalr').build();

    this._hubConnection.start().then(()=>{
      sessionStorage["connectionId"] = this._hubConnection.connectionId;
    }).catch((err: any)=>{console.info('Error while establishing the connection', err)});

    this._hubConnection.on('BroadcastMessage', (strNewMail: string, title: string, message: string)=>{
      console.info('BroadcastMessage');
    });
    
    this._hubConnection.on('NewMail', (objNewMail: any, title: string, message: string)=>{
      if(sessionStorage["User"] == objNewMail.mailTo.toLowerCase())
      {
        this.newmailCount.next(this.newmailCount.value +1);
        this.eMailService.getByKey(objNewMail.mailUID);
      }
    });
  }
}