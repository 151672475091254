import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailService } from '../_service/email.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit{
  constructor(private router:Router, private _emailService:EmailService){}
  ngOnInit(): void {
    console.info("logout");
    this._emailService.eMailService.clearCache();
    sessionStorage.clear();
    this.router.navigate(["/"]);
  }
}
