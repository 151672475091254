import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { EmailService } from 'src/app/_service/email.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  constructor(private _emailService:EmailService, private router:Router){}
  private mails:any;
  isLogin : boolean = false;

  ngOnInit(): void {
    this._emailService.eMailService.entities$.subscribe(ml => this.mails = ml);
    this.router.events.subscribe((val) => {
      let activationEnd : ActivationEnd;
      if (val instanceof ActivationEnd) {
        activationEnd= val || {};
        let cmp = activationEnd.snapshot.component || {'name':''};
          let componentName = cmp['name'];
          if(componentName == 'LoginComponent'){
            this.isLogin=true;
          }else{
            this.isLogin =false;
          }
      }
  });
  }
}
