import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { EntityCollectionService, EntityCollectionServiceFactory } from '@ngrx/data';
import { map, Observable, of } from 'rxjs';
import { IeMail } from 'src/app/store/i-email';
import { EmailService } from 'src/app/_service/email.service';

@Component({
  selector: 'app-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.css']
})
export class EmailListComponent implements OnInit{
  @ViewChild('ulMailList') ulMailList?: ElementRef;
  constructor(private _emailService:EmailService, private route: ActivatedRoute){ 
    route.params.subscribe(rut => this.currentMailUID = rut['id'] || 0 );
    this._emailService.currentMail$?.subscribe (ml=> {
      this.currentMailUID = ml?.mailUID;
      setTimeout(()=>{this.toggleNewMail(this)}, 2000);
    });
    this.currentMailID = window.location.pathname.replace("/",''); //route.snapshot['_routerState'].url
    if(!this.currentMailID.includes('@')) { this.currentMailID += '@sgssys.info';}
    sessionStorage["User"] = this.currentMailID.toLowerCase();
    this._emailService.newmailCount.subscribe(newmail => this.newmailCount = newmail);
  }
  currentMailID:string ="";
  currentMailUID: number =0;
  alleMails$: Observable<any> = new Observable<any>();
  isSelectAll:boolean = false;
  newmailCount:number =0;  
  ngOnInit(): void {
      console.info("email list init ");
      this._emailService.eMailService.entities$.subscribe(mail =>{
         this.alleMails$ = of(mail);
      });
      if(window.location.pathname.indexOf('inbox*mt')>0){
        this._emailService.eMailService.getAll();
      }else{
        this._emailService.eMailService.loadWithQuery("ToMailID=" + this.currentMailID);
      }
      let event = new KeyboardEvent('keyup',{'bubbles':true});
      this.ulMailList?.nativeElement.dispatchEvent(event);
    }
  addMail(mailUID: number){
    this._emailService.eMailService.getByKey(mailUID);
  }
  viewMail(mailUID: number, _event:any){
    _event.preventDefault();
    this._emailService.eMailService.entities$.subscribe(dta => {
      let aryMail: any[];
        aryMail =dta;
        aryMail = aryMail || [];
      this._emailService.currentMail$.next(aryMail.filter(dt => dt.mailUID == mailUID)[0]);
      setTimeout(()=>{this.toggleNewMail(this)}, 2000);
    });
    this._emailService.currentMailUID = mailUID;
    this.isSelectAll = true;
    this.toggleSelectAll();
    setTimeout(()=>{this.toggleNewMail(this)}, 2000);
  }
  delMail(mailUID: number, _event:any){
    _event.preventDefault();
    if(this._emailService.currentMail$.getValue().isNewMail){
      this._emailService.newmailCount.next(this._emailService.newmailCount.value -1);
    }
    return this._emailService.eMailService.delete(mailUID);
  }
  delMails(_event:any){
    _event.preventDefault();
    if(!this.isSelectAll){return;}
    this._emailService.eMailService.delete(this.currentMailID);
    this._emailService.newmailCount.next(0);
    this._emailService.eMailService.clearCache();
    this.isSelectAll = false;
  }
  toggleNewMail($this:any):void{
    console.info("toggleNewMail");
    let strNewMail = JSON.stringify($this._emailService.currentMail$.getValue());
    let jsonNewMail = JSON.parse(strNewMail);
    if(jsonNewMail.isNewMail){
      this._emailService.newmailCount.next(this._emailService.newmailCount.value -1);
      jsonNewMail.isNewMail =0;
      $this._emailService.eMailService.updateOneInCache(jsonNewMail);
    }

  }
  toggleSelectAll(): void {
    this.isSelectAll = !this.isSelectAll;
    if(this.isSelectAll){
      document.querySelectorAll('.mail-grid.not-active').forEach(node => node.classList.add('active'));
    }else{
      document.querySelectorAll('.mail-grid.not-active').forEach(node => node.classList.remove('active'));
    }
 }
}
