<section class="inner-content-style">
    <div class="d-flex">
        <div class="left-side-mail-box">
            <div class="header-inbox">
                <div>
                    <h3><img src="/assets/images/mail-inbox.svg" class="mail-icon">Inbox</h3>
                </div>
                <div class="notification">
                    <span>{{newmailCount}}</span>
                    <p>New Messages</p>
                </div>
                <div class="notification">
                    <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" (click)="toggleSelectAll()" [(ngModel)]="isSelectAll">
                      </label>
                   <a href="#" (click)="delMails($event)"><img src="/assets/images/bin.svg" width="22px" class="bin"></a> 
                </div>
                
            </div>
            <div class="mail-grid" [attr.mailUID]="mail?.mailUID" [ngClass]="(currentMailUID == mail?.mailUID? 'active': 'not-active')"
                    *ngFor="let mail of (alleMails$ | async).slice().reverse()"> 
                     <!-- (click)="viewMail(mail.mailUID, $event)"> -->
                <a href="" (click)="viewMail(mail.mailUID, $event)">
                    <img _ngcontent-vkd-c2="" src="/assets/images/mail-notify.png" class="mail-notify" *ngIf="mail?.isNewMail">
                    <div class="leftdiv"><h4>{{mail.mailSubject}}</h4><p>{{mail.mailFrom}}</p></div>
                    <div class="date-and-func" (click)="delMail(mail.mailUID, $event)">
                        <img src="/assets/images/bin.svg" class="bin-width">
                        <span>{{mail.mailDate | date : 'dd-MM'}}</span> 
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
