<header>
    <div class="header-area">
        <div>
            <img src="/assets/images/sgs-inner-logo.svg">
        </div>
        <div>
            <button class="logout-btn"><a [routerLink]="['/logout']" accesskey="l"><img src="/assets/images/logout.svg" class="logout-icon">Logout</a></button>
        </div>
    </div>
   </header>
