import { Injectable } from "@angular/core";
import { DefaultHttpUrlGenerator, HttpResourceUrls, Pluralizer } from "@ngrx/data";

import {AppConstants} from 'src/app/_helper/app.constants';

@Injectable()
export class CustomHttpGenerator extends DefaultHttpUrlGenerator {
    constructor(pluralizer: Pluralizer) { super(pluralizer); }
    
    protected override getResourceUrls
    (entityName: string, root: string, trailingSlashEndpoints?: boolean | undefined): HttpResourceUrls {
        let resourceUrls = this.knownHttpResourceUrls[entityName];

        if(entityName == 'eMail'){
            resourceUrls ={
                collectionResourceUrl: AppConstants.API_BASE_URL + 'email/',
                entityResourceUrl: AppConstants.API_BASE_URL + 'email/' 
            }
            this.registerHttpResourceUrls({[entityName]: resourceUrls});
        }
        return resourceUrls;
    }
}
