import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IeMail } from 'src/app/store/i-email';
import { EmailService } from 'src/app/_service/email.service';

@Component({
  selector: 'app-email-view',
  templateUrl: './email-view.component.html',
  styleUrls: ['./email-view.component.css']
})
export class EmailViewComponent implements OnInit {
  // @Input('currentMail') public currentMail:any = {htmlBody:"", mailUID: 0};
  
  constructor(private _emailService:EmailService){ 
    this.currentMail$ = _emailService.currentMail$;
  }
  currentMail$:Observable<IeMail>;
  currentMailUID: number =0;
  ngOnInit(): void {
    this.currentMailUID = this._emailService.currentMailUID;
    if (this._emailService.currentMailUID > 0){
      this._emailService.eMailService.entities$.subscribe(dta => 
        this._emailService.currentMail$.next(dta.filter(dt => dt.mailUID == this.currentMailUID)[0])
      );
      
    }else{
      this.currentMail$ = this._emailService.currentMail$;
    }
  }

}
