<section class="login-background">
  <div class="container">
      <div class="row login-form">
          <div class="col-lg-6 col-sm-12 padding-50">
              <img src="assets/images/sgs-logo.svg" class="sgs-logo-login">
              <h3 class="title-login">SGS Mail Login </h3>
              <form (ngSubmit)="doLogin($event)">
                  <div class="input-group mb-3">
                    <input type="text" placeholder="Username" aria-label="Username" class="form-control" [(ngModel)]="Username" [ngModelOptions]="{standalone: true}">
                    <span class="input-group-text">@</span><select aria-label="Domain Name" class="form-select">
                        <option>-- Select --</option>
                        <option selected="selected">sgssys.info</option>
                  </select></div>

                  <div class="form-group d-flex justify-content-center mt-4 clearfix">
                      <a href="#" (click)="doLogin($event)" accesskey="l">
                          <div type="submit" class="btn-login-color"> Login</div>
                      </a>
                  </div>
              </form>
              
          </div>
          <div class="col-lg-6 col-sm-12 no-padding login-img-div">
              <div class="responsive_img"></div>
          </div>
      </div>
  </div>

</section>
